<template>
  <div class="create_wrapper add-order">
    <!-- Start Breadcrumb -->
    <Breadcrumb :items="items" />
    <!-- End Breadcrumb -->

    <div class="custom_card">
      <div class="card-header">
        <h4 class="card-title">{{ $t("addNew") }}</h4>
      </div>

      <!-- ==== Start Form ==== -->
      <form @submit.prevent="validateCreateForm">
        <div class="container">
          <div class="row justify-content-between">
            <!-- Start:: Is Active -->
            <div class="col-lg-6 py-0">
              <v-checkbox
                :label="$t('forms.labels.is_active')"
                v-model="data.is_active"
                color="success"
              ></v-checkbox>
            </div>
            <!-- End:: Is Active -->
            <!-- Start:: Is Active -->
            <!-- <div class="col-lg-6 py-0">
              <v-checkbox
                :label="$t('forms.labels.is_with_coupon')"
                v-model="data.is_with_coupon"
                color="success"
              ></v-checkbox>
            </div> -->
            <!-- End:: Is Active -->
            <div class="row justify-center align-center">
              <div class="col-md-6">
                <label for="name_input" class="fs-6 text-center w-100">
                  {{ $t("forms.labels.image") }}</label
                >
                <uplode-image
                  @inputChanged="uploadImage"
                  :placeholder="$t('forms.labels.image')"
                ></uplode-image>
              </div>
            </div>

            <!-- End:: Image -->

            <!-- <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  v-model.trim="data.ar.name"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.name_ar") }}
                </label>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  v-model.trim="data.en.name"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.name_en") }}
                </label>
              </div>
            </div> -->

            <!-- <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <label for="textarea_1" class="form-label">
                  {{ $t("forms.labels.desc_ar") }}
                </label>
                <textarea
                  class="form-control"
                  rows="5"
                  v-model="data.ar.desc"
                ></textarea>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <label for="textarea_1" class="form-label">
                  {{ $t("forms.labels.desc_en") }}
                </label>
                <textarea
                  class="form-control"
                  rows="5"
                  v-model="data.en.desc"
                ></textarea>
              </div>
            </div> -->

            <!-- <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <label class="form-label">نوع العرض</label>
                <multiselect
                  v-model="data.type"
                  :options="types"
                  label="name"
                  track-by="id"
                  placeholder=" "
                  :searchable="true"
                  :allow-empty="false"
                  :show-labels="false"
                ></multiselect>
              </div>
            </div> -->
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  v-model.trim="data.code"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.code") }}
                </label>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="number"
                  class="form-control"
                  v-model.trim="data.order_price"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.order_price") }}
                </label>
              </div>
            </div>
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="number"
                  class="form-control"
                  v-model.trim="data.max_use"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.NumOfUse") }}
                </label>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="number"
                  class="form-control"
                  v-model.trim="data.num_of_use"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.maxClientUse") }}
                </label>
              </div>
            </div>
            <!-- <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="number"
                  class="form-control"
                  v-model.trim="data.ordering"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.ordering") }}
                </label>
              </div>
            </div> -->

            <!-- Start:: Start Date -->
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label date_input">
                <label class="form-label">
                  {{ $t("forms.labels.start_date") }}
                </label>
                <v-menu
                  v-model="dateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="data.start_date"
                      label="Picker without buttons"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="data.start_date"
                    @input="dateMenu = false"
                    no-title
                  ></v-date-picker>
                </v-menu>
              </div>
            </div>
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label date_input">
                <label class="form-label">
                  {{ $t("forms.labels.start_time") }}
                </label>
                <v-dialog
                  ref="start_time_dialog"
                  v-model="start_time_modal"
                  :return-value.sync="data.start_time"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="data.start_time"
                      label="Picker in dialog"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="start_time_modal"
                    v-model="data.start_time"
                    full-width
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="start_time_modal = false"
                    >
                      {{ $t("cancel") }}
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.start_time_dialog.save(data.start_time)"
                    >
                      {{ $t("done") }}
                    </v-btn>
                  </v-time-picker>
                </v-dialog>
              </div>
            </div>
            <!-- End:: Start Date -->

            <!-- Start:: End Date -->
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label date_input">
                <label class="form-label">
                  {{ $t("forms.labels.end_date") }}
                </label>
                <v-menu
                  v-model="dateMenu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="data.end_date"
                      label="Picker without buttons"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="data.end_date"
                    @input="dateMenu2 = false"
                    no-title
                  ></v-date-picker>
                </v-menu>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label date_input">
                <label class="form-label">
                  {{ $t("forms.labels.end_time") }}
                </label>
                <v-dialog
                  ref="dialog"
                  v-model="end_time_modal"
                  :return-value.sync="data.end_time"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="data.end_time"
                      label="Picker in dialog"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="end_time_modal"
                    v-model="data.end_time"
                    full-width
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="end_time_modal = false">
                      {{ $t("cancel") }}
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(data.end_time)"
                    >
                      {{ $t("done") }}
                    </v-btn>
                  </v-time-picker>
                </v-dialog>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <multiselect
                  v-model="data.apply_on"
                  :options="applys_on"
                  label="name"
                  track-by="id"
                  :loading="appliesListLoading"
                  placeholder=" "
                  return="id"
                  @input="getAppliesList($event)"
                  :searchable="true"
                  :allow-empty="false"
                  :show-labels="false"
                ></multiselect>
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.productOrSec") }}
                </label>
              </div>
            </div>
            <div class="col-lg-6 py-0" v-if="listType == 'special_products'">
              <div class="input_wrapper top_label">
                <multiselect
                  v-model="data.apply_ids"
                  :options="appliesList"
                  label="name"
                  track-by="id"
                  placeholder=" "
                  return="id"
                  :multiple="true"
                  :searchable="true"
                  :allow-empty="false"
                  :show-labels="false"
                ></multiselect>
                <label for="name_input" class="form-label">
                  {{
                    data.apply_on
                      ? data.apply_on.name
                      : $t("titles.displayType")
                  }}
                </label>
              </div>
            </div>
            <div class="col-lg-6 py-0" v-if="listType == 'special_categories'">
              <div class="input_wrapper top_label">
                <label class="form-label">
                  {{ $t("forms.labels.category") }}
                </label>
                <treeselect
                  dir="rtl"
                  :multiple="true"
                  placeholder=""
                  :options="appliesList"
                  :disable-branch-nodes="true"
                  :show-count="true"
                  :flat="true"
                  @input="getRootCategory($event)"
                  :branchNodesFirst="true"
                  v-model="data.apply_ids"
                />
              </div>
            </div>
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <multiselect
                  v-model="data.discount_type"
                  :options="discount_types"
                  label="name"
                  track-by="id"
                  placeholder=" "
                  return="id"
                  :searchable="true"
                  :allow-empty="false"
                  :show-labels="false"
                ></multiselect>
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.discountType") }}
                </label>
              </div>
            </div>

            <!-- End:: End Date -->

            <div class="col-lg-6 py-0" v-if="false">
              <!-- v-if="row.apply_on == 'special_categories'" -->
              <div class="input_wrapper top_label">
                <label class="form-label">
                  {{ $t("forms.labels.category") }}
                </label>

                <multiselect
                  v-model="row.category"
                  :options="categories"
                  label="name"
                  track-by="id"
                  placeholder=" "
                  :searchable="true"
                  :allow-empty="false"
                  :show-labels="false"
                ></multiselect>
              </div>
            </div>
            <div class="col-lg-6 py-0" v-if="false">
              <!-- v-if="row.apply_on == 'special_products'" -->
              <div class="input_wrapper top_label">
                <label class="form-label">
                  {{ $t("forms.labels.product") }}
                </label>

                <multiselect
                  v-model="row.product"
                  :options="products"
                  label="name"
                  track-by="id"
                  placeholder=" "
                  :searchable="true"
                  :allow-empty="false"
                  :show-labels="false"
                  @input="getProductDetails(index)"
                ></multiselect>
              </div>
            </div>

            <!-- applys on -->

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="number"
                  class="form-control"
                  v-model="data.discount_amount"
                  :max="data.discount_type.id == 'percentage' ? 100 : ''"
                />
                <label for="name_input" class="form-label">
                  {{
                    data.discount_type.id == "percentage"
                      ? $t("forms.labels.discountPercentage")
                      : data.discount_type.id == "value"
                      ? $t("forms.labels.discountValue")
                      : $t("forms.labels.discountPoints")
                  }}
                </label>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="number"
                  class="form-control"
                  v-model.trim="data.max_amount"
                  :max="data.discount_type.id == 'percentage' ? 100 : ''"
                />
                <label for="name_input" class="form-label">
                  {{
                    data.discount_type.id == "percentage"
                      ? $t("forms.labels.discountMaxPercenatge")
                      : data.discount_type.id == "value"
                      ? $t("forms.labels.discountMaxValue")
                      : $t("forms.labels.discountMaxPoints")
                  }}
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="buttons_wrapper">
          <button
            class="button_style_1"
            :disabled="btnIsLoading"
            :class="btnIsLoading ? 'disabled' : ''"
          >
            {{ $t("forms.submit") }}
            <span class="btn_loader" v-if="btnIsLoading"></span>
          </button>
        </div>
      </form>
      <!-- ==== End Form ==== -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Create",

  data() {
    return {
      btnIsLoading: false,
      appliesListLoading: false,
      dateMenu: false,
      dateMenu2: false,

      start_time_modal: false,
      end_time_modal: false,
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.coupons.title"),
          disabled: false,
          href: "/coupons/show-all",
        },
        {
          text: this.$t("breadcrumb.coupons.add"),
          disabled: true,
          href: "",
        },
      ],

      // ========== Loading

      // ========== create_data
      data: {
        // ar: {
        //   app_img: null,
        //   website_img: null,
        //   title: null,
        //   desc: null,
        // },
        // en: {
        //   app_img: null,
        //   website_img: null,
        //   title: null,
        //   desc: null,
        // },
        image: null,
        code: null,
        display_platform: "both",
        ordering: "",
        max_use: null,
        num_of_use: null,
        type: null,

        start_date: null,
        start_time: null,
        end_date: null,
        end_time: null,

        is_with_coupon: false,
        is_active: true,
        discount_amount: null,
        order_price: null,
        max_amount: null,
        apply_ids: [],
        discount_type: {
          id: "value",
          name: this.$t("value"),
        },
        apply_on: null,
      },
      types: [
        {
          id: "cashback",
          name: this.$t("cashback"),
        },
        {
          id: "top_deal",
          name: this.$t("top_deal"),
        },
        {
          id: "points",
          name: this.$t("points"),
        },
      ],
      discount_types: [
        {
          id: "value",
          name: this.$t("value"),
        },
        {
          id: "percentage",
          name: this.$t("percentage"),
        },
        // {
        //   id: "points",
        //   name: this.$t("points"),
        // },
      ],
      applys_on: [
        {
          id: "special_products",
          name: this.$t("special_products"),
        },
        {
          id: "special_categories",
          name: this.$t("special_categories"),
        },
        {
          id: "all",
          name: this.$t("all"),
        },
      ],
      rootsCategories: [],
      appliesList: [],
      listType: "",
    };
  },

  computed: {
    min_type_option() {
      return [
        {
          id: "1",
          name: "quantity_of_products",
        },
        {
          id: "1",
          name: "amount_of_total_price",
        },
      ];
    },
  },

  methods: {
    uploadImage(obj) {
      this.data.image = obj;
    },
    // uploadAppImgEn(obj) {
    //   this.data.en.app_img = obj;
    // },
    // uploadWebImgAr(obj) {
    //   this.data.ar.website_img = obj;
    // },
    // uploadWebImgEn(obj) {
    //   this.data.en.website_img = obj;
    // },
    resetApplys(e) {
      this.data.applys_ids = [];
    },

    // ====== Validate Data
    validateCreateForm() {
      this.btnIsLoading = true;

      if (!this.data.image) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.image"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.code) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.code"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.start_date) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.start_date"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.start_time) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.start_time"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.end_date) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.end_date"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.end_time) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.end_time"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.discount_type) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.discount_type"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.max_use) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.maxNumOfUse"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.num_of_use) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.labels.numOfUse"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else {
        this.submitData();
        return;
      }
    },

    submitData() {
      const submit_data = new FormData();
      if (this.data.image?.img_file) {
        submit_data.append("image", this.data.image.img_file);
      }
      // submit_data.append("ar[name]", this.data.ar.name);
      // submit_data.append("en[name]", this.data.en.name);
      // submit_data.append("ar[desc]", this.data.ar.desc);
      // submit_data.append("en[desc]", this.data.en.desc);
      // submit_data.append("ordering", this.data.ordering);

      submit_data.append(
        "start_at",
        `${this.data.start_date} ${this.data.start_time}`
      );
      submit_data.append(
        "end_at",
        `${this.data.end_date} ${this.data.end_time}`
      );

      // submit_data.append("display_platform", this.data.display_platform);
      // submit_data.append("type", this.data.type.id);
      submit_data.append("code", this.data.code);
      submit_data.append("max_used_num", this.data.max_use);
      submit_data.append("max_used_for_user", this.data.num_of_use);
      submit_data.append("order_price", this.data.order_price);

      submit_data.append("discount_type", this.data.discount_type.id);
      submit_data.append("discount_amount", this.data.discount_amount);
      submit_data.append("max_discount", this.data.max_amount);

      // submit_data.append("is_with_coupon", +this.data.is_with_coupon);
      submit_data.append("is_active", +this.data.is_active);
      submit_data.append(`apply_coupon_on`, this.data.apply_on.id);
      this.data.apply_ids.map((el, index) =>
        submit_data.append(`apply_ids[${index}]`, el.id ?? el)
      );

      this.$axios({
        method: "POST",
        url: "coupon",
        data: submit_data,
      })
        .then(() => {
          this.btnIsLoading = false;
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("addSuccess"),
            position: "bottomRight",
          });
          this.$router.push({ path: "/coupons/show-all" });
        })
        .catch((err) => {
          if (err.response) {
            this.$iziToast.error({
              timeout: 2000,
              message: err.response.data.message,
              position: "bottomRight",
            });
          } else if (err.request) {
            this.$iziToast.error({
              timeout: 2000,
              message: err.request.response,
              position: "bottomRight",
            });
          } else {
            this.$iziToast.error({
              timeout: 2000,
              message: err.message,
              position: "bottomRight",
            });
          }

          this.btnIsLoading = false;
        });
    },
    getAppliesList(event) {
      let url = "";
      this.data.apply_ids = [];
      if (event.id == "special_products") {
        url = "get_all_product";
        this.listType = "special_products";
      } else if (event.id == "special_categories") {
        url = "category_tree";
        this.listType = "special_categories";
      } else {
        this.appliesList = null;
      }
      if (url) {
        this.appliesListLoading = true;
        this.$axios
          .get(url)
          .then((res) => {
            this.appliesListLoading = false;
            if (url == " get_all_product") {
              this.appliesList = res.data.data.map((el) => ({
                id: el.id,
                name: el.name ? el.name : el[this.$i18n.locale].label,
              }));
            } else {
              this.rootsCategories = res.data.data;
              this.appliesList = res.data.data;
            }
          })
          .catch(() => (this.appliesListLoading = false));
      }
    },
    getRootCategory(id) {
      if (id.length) {
        this.$axios({
          method: "GET",
          url: `category/${id[0]}/get_root`,
        })
          .then((res) => {
            this.rootCategory = res.data.data;
            this.appliesList = this.appliesList.filter(
              (el) => el.id == res.data.data.id
            );
            this.data.apply_ids = id;
          })
          .catch(() => this.getAppliesList());
      } else {
        this.appliesList = this.rootsCategories;
      }
    },
    // ====== Submit Data
  },

  created() {},
};
</script>

<style lang="scss">
.add-order {
  .productRow {
    border: 1px solid #339989;
    border-radius: 15px;
    padding: 10px 10px 20px 10px;

    .detail {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 10px;
      }
      .detail_show {
        border: 1px solid #339989;
        padding: 10px;
        flex-grow: 1;
        border-radius: 15px;
        position: relative;
        min-height: 100px;
        display: flex;
        align-items: center;
        justify-content: space-around;

        .rate {
          color: gray;
          position: absolute;
          top: 5px;
          left: 5px;
          font-size: 13px;

          span {
            color: yellow;
          }
        }

        .image {
          img {
            max-height: 50px;
          }
        }

        .color,
        .size {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }

        .image {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }

        .key {
          margin-bottom: 5px;
        }
      }
    }
  }
  label.form-label.title {
    color: #339989 !important;
  }
}
</style>
